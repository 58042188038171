import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { CMSHomepageType } from '@utils/types/cmsHomepage';
import { MarketType } from '@utils/types/city';
import HeroSearch from 'components/home/heroSearch';
import VimeoBackground from '@components/_shared/vimeoBackground';
import { DatesRange, FuzzyDatesOffset } from '@utils/types/calendar';
import { ModalNames, useModals } from '@utils/context/modals';
import { getIsNewsletterModalShown } from '@utils/localStorage';

import styles from './styles.module.scss';

type HeroSectionType = {
  title: CMSHomepageType['title'];
  subtitle: CMSHomepageType['subtitle'];
  markets: MarketType[];
};

const HeroSection = (props: HeroSectionType) => {
  const { title, subtitle, markets } = props;
  const router = useRouter();
  const { openModal } = useModals();

  useEffect(() => {
    if (typeof localStorage === 'undefined') {
      return;
    }

    let timeout: NodeJS.Timeout;
    const isNewsletterModalShown = getIsNewsletterModalShown();
    if (!isNewsletterModalShown) {
      timeout = setTimeout(() => {
        openModal(ModalNames.SIGN_UP_FOR_NEWSLETTER);
      }, 20000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onMarketAndDatesSelected = (
    market: string,
    { from, to }: DatesRange,
    fuzzyDatesOffset: FuzzyDatesOffset
  ) => {
    router.push({
      pathname: '/search',
      query: {
        ...(!!market && { market }),
        ...(!!from && {
          dates: !!to
            ? [from.toISOString(), to.toISOString()]
            : [from.toISOString(), from.toISOString()],
        }),
        ...(fuzzyDatesOffset && {
          offsetFrom: fuzzyDatesOffset.from?.toString(),
          offsetTo: fuzzyDatesOffset.to?.toString(),
        }),
      },
    });
  };

  return (
    <VimeoBackground containerStyle={styles.hero}>
      <div className={styles.hero__wrapper}>
        <h1 className={styles.hero__title}>{title}</h1>
        <h4 className={styles.hero__subtitle}>{subtitle}</h4>
        <HeroSearch markets={markets} onSearch={onMarketAndDatesSelected} />
      </div>
    </VimeoBackground>
  );
};

export default HeroSection;
