import React from 'react';

import { CMSHomepageType } from '@utils/types/cmsHomepage';

import styles from './styles.module.scss';

const PressReviews = (props: CMSHomepageType['pressReviews']) => {
  const { title, items } = props;

  return (
    <div className={styles.pressReviews}>
      <h2 className={styles.pressReviews__title}>{title}</h2>

      <div className={styles.pressReviews__items}>
        {items?.map((item) => (
          <div key={item._key} className={styles.pressReviews__item}>
            <div className={styles.pressReviews__itemText}>{item.review}</div>

            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={item.sourceLogo}
              alt="source logo"
              className={styles.pressReviews__itemLogo}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PressReviews;
